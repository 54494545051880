.root{
    flex-grow: 1;
}

.container {
    padding: 10px 5px 5px 10px;
    text-align: center;
}

.paper {
    padding: 10px 5px 5px 10px;
    text-align: center;
}

.gridAlignLeft{
    text-align: left;
}

.buttonGroup{
    display: inline-block;
}

.buttonDiv{
    display: inline-block;
    margin: 5px;
}
